<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4>أحدث المكالمات</h4>
      </div>
      <div class="card-body">
        <div class="col-12 table-responsive">
          <table
            class="table table-hover table-bordered custom-table table-striped"
          >
            <thead>
              <th>رقم المكالمة</th>
              <th>العميل</th>
              <th>الحالة</th>
              <th>الخدمة</th>
              <th>تاريخ الاتصال</th>
              <th>المشرف</th>
              <th>خيارات</th>
            </thead>
            <tbody>
              <tr
                v-for="c in calls"
                :key="c._id"
                :class="
                  c.status == 3 && !c.missed_seen
                    ? 'bg-light-danger'
                    : c.admin_id == user._id && c.status == 1
                    ? 'bg-light-success'
                    : c.admin_id == user._id
                    ? 'border-left-success'
                    : ''
                "
              >
                <td>{{ c.number }}</td>
                <td>
                  <a :href="'/clients/client/' + c.client._id" target="_blank">
                    {{ c.client.name }}
                    <i class="fa fa-external-link"></i>
                    <br />
                    <small> {{ c.client.phone }}</small>
                  </a>
                  <div class="badge bg-warning" v-if="c.client.level == '1'">
                    <i class="fa fa-star"></i>
                    مهم
                  </div>
                  <div class="badge bg-danger" v-if="c.client.level == '2'">
                    <i class="fa fa-star"></i>
                    VIP
                  </div>
                </td>
                <td>
                  <span class="badge bg-warning" v-if="c.status == 0">
                    <i class="fa fa-clock-o"></i>
                    جاري الإتصال ({{ callTime(c.date, c.end_date) }})
                  </span>
                  <span class="badge bg-success" v-if="c.status == 1">
                    <i class="fa fa-phone"></i>
                    في المكالمة ({{ callTime(c.date, c.end_date) }})
                  </span>
                  <span
                    class="badge bg-primary"
                    v-if="c.status == 3 && c.missed_seen"
                  >
                    <i class="fa fa-info"></i>
                    فائتة ({{ callTime(c.date, c.end_date) }})
                  </span>
                  <span
                    class="badge bg-primary"
                    v-if="c.status == 3 && !c.missed_seen"
                    @click="seenMissed(c._id)"
                  >
                    <i class="fa fa-info"></i>
                    فائتة ({{ callTime(c.date, c.end_date) }})
                  </span>
                  <div v-if="c.status == 2 || c.status == 4">
                    <span class="badge bg-danger" v-if="c.ended_by == 0">
                      <i class="fa fa-times"></i>
                      انتهت ({{ callTime(c.date, c.end_date) }})
                    </span>
                    <span class="badge bg-danger" v-if="c.ended_by == 1">
                      <i class="fa fa-times"></i>
                      قُطع الاتصال عن المشرف ({{
                        callTime(c.date, c.end_date)
                      }})
                    </span>
                    <span class="badge bg-danger" v-if="c.ended_by == 2">
                      <i class="fa fa-times"></i>
                      العميل انهى ({{ callTime(c.date, c.end_date) }})
                    </span>
                    <span class="badge bg-danger" v-if="c.ended_by == 3">
                      <i class="fa fa-times"></i>
                      الادمن انهى ({{ callTime(c.date, c.end_date) }})
                    </span>
                    <span class="badge bg-danger" v-if="c.ended_by == 4">
                      <i class="fa fa-times"></i>
                      العميل قُطع اتصاله ({{ callTime(c.date, c.end_date) }})
                    </span>
                    <span class="badge bg-danger" v-if="c.ended_by == 5">
                      <i class="fa fa-times"></i>
                      الادمن رفض ({{ callTime(c.date, c.end_date) }})
                    </span>
                    <span class="badge bg-danger" v-if="c.ended_by == 6">
                      <i class="fa fa-times"></i>
                      العميل انهى قبل الرد ({{ callTime(c.date, c.end_date) }})
                    </span>
                  </div>
                  <div v-if="c.rate || c.client_notes" class="g">
                    <div
                      class="border bg-white text-dark"
                      style="max-width: 300px"
                    >
                      <span
                        class="fa fa-thumbs-up text-success"
                        v-if="c.rate == 1"
                      >
                        جيد</span
                      >
                      <span
                        class="fa fa-thumbs-down text-danger"
                        v-if="c.rate == 2"
                      >
                        سيئ</span
                      >
                      &nbsp;
                      <span>{{ c.client_notes }}</span>
                    </div>
                  </div>
                  <div v-if="c.admin_notes" class="g">
                    <div
                      class="border bg-white text-dark"
                      style="max-width: 300px"
                    >
                      المشرف: &nbsp;
                      <span>{{ c.admin_notes }}</span>
                    </div>
                  </div>
                </td>
                <td>
                  <span v-if="c.service != 'stranger' && c.service">
                    <img
                      :src="serviceImage(c.service)"
                      style="
                        width: 26px;
                        height: 26px;
                        object-fit: cover;
                        border-radius: 50%;
                      "
                    />&nbsp;{{ serviceName(c.service) }}
                  </span>
                </td>
                <td>
                  {{
                    c.date.split("T")[0] +
                    " " +
                    c.date.split("T")[1].split(".")[0]
                  }}
                </td>
                <td>
                  <span v-if="c.admin_id">
                    <img
                      :src="adminAvatar(c.admin_id)"
                      style="
                        width: 26px;
                        height: 26px;
                        object-fit: cover;
                        border-radius: 50%;
                      "
                      alt=""
                    />&nbsp;{{ adminName(c.admin_id) }}
                  </span>
                </td>
                <td>
                  <!-- primary -->
                  <b-dropdown
                    id="dropdown-1"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    text="خيارات"
                    size="sm"
                    right
                    variant="relief-primary"
                  >
                    <b-dropdown-item
                      v-if="c.status == 1 && c.admin_id == user._id"
                      @click="
                        call = c;
                        viewcall = true;
                      "
                    >
                      <i class="fa fa-expand"></i>
                      تكبير
                    </b-dropdown-item>

                    <b-dropdown-item
                      v-if="c.status == 1 && c.admin_id == user._id"
                      @click="endCall(c)"
                    >
                      <i class="fa fa-times"></i>
                      انهاء المكالمة
                    </b-dropdown-item>
                    <b-dropdown-item @click="callNotes(c)">
                      <i class="fa fa-edit"></i>
                      ملاحظاتك
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="callRecord(c)"
                      v-b-modal.record-modal
                    >
                      <i class="fa fa-file-audio-o"></i>
                      سماع المكالمة
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="current-call" v-if="call.number && viewcall">
      <div class="card card-body">
        <div class="row">
          <div class="col-12 col-lg-2"></div>
          <div class="col-12 col-lg-3 g">
            <div class="media">
              <a class="d-flex" href="#">
                <span
                  class="bg-success text-white"
                  style="
                    width: 80px;
                    height: 80px;
                    text-align: center;
                    color: white;
                    border-radius: 10px;
                  "
                >
                  <h4 class="text-white">
                    <br />
                    <i class="fa fa-clock-o"></i>
                    <br />
                    {{ callTime(call.date, call.end_date) }}
                  </h4>
                </span>
              </a>
              <div class="media-body g">
                <h4>{{ call.client.name }}</h4>
                {{ call.client.phone }}
                <span class="badge bg-warning" v-if="call.client.level == '1'">
                  <i class="fa fa-star"></i>
                  مهم
                </span>
                <span class="badge bg-danger" v-if="call.client.level == '2'">
                  <i class="fa fa-star"></i>
                  VIP
                </span>
                <br />
                <a href="#" @click="viewcall = false"
                  ><i class="fa fa-eye-slash"></i> إخفاء النافذة</a
                >
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-3 g">
            <br />
            <ul v-if="call.stats">
              <li>عدد المكالمات السابقة: {{ call.stats.calls }}</li>
              <li>عدد التذاكر: {{ call.stats.tickets }}</li>
              <li>عدد الرسائل: {{ call.stats.messages }}</li>
            </ul>
          </div>
          <div class="col-12 col-lg-2 g">
            <div class="row">
              <div class="col-6">
                <button
                  :class="[
                    'btn btn-block ',
                    muted ? 'btn-relief-danger' : 'btn-relief-primary',
                  ]"
                  @click="
                    muted = !muted;
                    mediaStream.getAudioTracks()[0].enabled = !muted;
                  "
                >
                  <i class="fa fa-microphone" v-if="!muted"></i>
                  <i class="fa fa-microphone-slash" v-if="muted"></i>
                  كتم
                </button>
              </div>
              <div class="col-6">
                <button
                  class="btn btn-block btn-relief-info"
                  @click="forward(call)"
                  v-b-modal.forward-modal
                >
                  <i class="fa fa-reply"></i>
                  تحويل
                </button>
              </div>
            </div>
            <br />
            <button
              class="btn btn-block btn-relief-danger"
              @click="endCall(call)"
            >
              <i class="fa fa-times"></i>
              إنهاء المكالمة
            </button>
          </div>

          <div class="col-12 col-lg-2"></div>
        </div>
      </div>
    </div>
    <div class="income-call">
      <div v-for="calla in calls" :key="calla._id">
        <div class="card" v-if="calla.number && calla.status == 0">
          <div class="card-body">
            <div
              class="col-12 text-center text-danger"
              v-if="calla.forwarded_from != ''"
            >
              <i class="fa fa-reply"></i>
              <h5>رسالة محولة من {{ calla.forwarded_from }}</h5>
            </div>
            <div class="media">
              <a class="d-flex" href="#">
                <img
                  v-if="calla.service == 'stranger' || !calla.service"
                  :src="require('@/assets/images/call.png')"
                  style="width: 40px; position: relative; top: 13px"
                  alt=""
                />
                <img
                  v-if="calla.service != 'stranger' && calla.service"
                  :src="serviceImage(calla.service)"
                  style="width: 40px; position: relative; top: 13px"
                  alt=""
                />
              </a>
              <div class="media-body g">
                <h5>{{ calla.client.name }}</h5>
                {{ calla.client.phone }}
                <span class="badge bg-warning" v-if="calla.client.level == '1'">
                  <i class="fa fa-star"></i>
                  مهم
                </span>
                <span class="badge bg-danger" v-if="calla.client.level == '2'">
                  <i class="fa fa-star"></i>
                  VIP
                </span>
              </div>
            </div>
          </div>
          <div class="card-footer g">
            <div class="row">
              <div class="col-6">
                <button
                  v-if="!call.number"
                  @click="acceptCall(calla)"
                  class="btn btn-block btn-success"
                  style="width: 100% !important"
                >
                  <i class="fa fa-check"></i>
                  قبول
                </button>
              </div>
              <div class="col-6">
                <button
                  @click="rejectCall(calla)"
                  class="btn btn-block btn-outline-danger"
                  style="width: 100% !important"
                >
                  <i class="fa fa-times"></i>
                  رفض
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- record modal -->
    <b-modal id="record-modal" size="lg" title="سماع المكالمة" hide-footer>
      <div class="col-12 text-center" v-if="records_loading">
        <img :src="require('@/assets/images/loading.svg')" alt="" />
      </div>
      <div class="col-12 text-center" v-if="!records_loading">
        <img
          :src="require('@/assets/images/phone.gif')"
          style="width: 200px"
          alt=""
        />
      </div>
    </b-modal>
    <!-- forward modal -->
    <b-modal id="forward-modal" size="lg" title="تحويل المكالمة" hide-footer>
      <div class="col-12 table-responsive">
        <table class="table table-hover table-bordered">
          <thead>
            <th>الإسم</th>
            <th>متوفر</th>
            <th>اونلاين</th>
            <th>تحويل</th>
          </thead>
          <tbody>
            <tr v-for="admin in realtime_admins" :key="admin._id">
              <td>
                {{ admin.name }}
              </td>
              <td>
                {{ admin.available ? "نعم" : "لا" }}
              </td>
              <td>
                {{ admin.online ? "نعم" : "لا" }}
              </td>
              <td>
                <button
                  class="btn btn-relief-info"
                  @click="forwardNow(call, admin._id)"
                >
                  تحويل الآن
                  <i class="fa fa-reply"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { BDropdown, BDropdownItem, BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { Peer } from "peerjs";

export default {
  components: {
    BDropdown,
    BModal,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      mediaStream: null,
      call: {},
      audio: null,
      calls: [],
      viewcall: true,
      user: JSON.parse(localStorage.getItem("user")),
      admins: [],
      muted: false,
      services: [],
      records: [],
      records_loading: false,
      record_audio: null,
      realtime_admins: [],
    };
  },
  methods: {
    forward(call) {
      var g = this;
      $.post(api + "/console/general/admins-names", {
        jwt: this.user.jwt,
      }).then(function (data) {
        g.realtime_admins = JSON.parse(data).response;
      });
    },
    forwardNow(call, id) {
      var g = this;
      $.post(api + "/console/calls/forward", {
        jwt: this.user.jwt,
        id: call._id,
        admin_id: id,
      }).then(function (data) {
        if (JSON.parse(data).status == 100) {
          location.reload();
        } else {
          alert("المستخدم غير متصل، لا يمكن تحويل المكالمة له");
        }
      });
    },
    callRecord(call) {
      var g = this;
      g.records_loading = true;
      $.post(api + "/console/calls/record", {
        jwt: this.user.jwt,
        id: call._id,
      }).then(function (data) {
        g.records_loading = false;
        var url = "data:audio/ogg;base64,";
        var sounds = [];
        data.split("\n").forEach(function (bar) {
          bar = bar.replace("from admin:", "").replace("from client:", "");
          sounds.push(new Audio(url + bar.trim()));
        });
        function play(audio, callback) {
          audio.play();
          if (callback) {
            audio.onended = callback;
          }
        }
        function queue_sounds(sounds) {
          var index = 0;
          function recursive_play() {
            if (index + 1 === sounds.length) {
              play(sounds[index], null);
              alert("انتهت المكالمة هنا!");
              $("#record-modal___BV_modal_header_ > button").click();
            } else {
              play(sounds[index], function () {
                index++;
                recursive_play();
              });
            }
          }
          recursive_play();
        }
        queue_sounds(sounds);
        //
      });
    },
    endCall(call) {
      $.post(api + "/console/calls/end", {
        jwt: this.user.jwt,
        id: call._id,
      }).then(function () {
        location.reload();
        //
      });
    },
    adminAvatar(id) {
      var r = null;
      if (this.admins[id]) {
        r = this.admins[id].avatar;
      }
      return r;
    },
    adminName(id) {
      var r = null;
      if (this.admins[id]) {
        r = this.admins[id].name;
      }
      return r;
    },
    serviceImage(id) {
      var r = null;
      if (this.services[id]) {
        r = this.services[id].image;
      }
      return r;
    },
    serviceName(id) {
      var r = null;
      if (this.services[id]) {
        r = this.services[id].title;
      }
      return r;
    },
    callNotes(call) {
      var text = prompt("ملاحظاتك:", call.admin_notes);
      if (text != null && text != "") {
        $.post(api + "/console/calls/notes", {
          jwt: this.user.jwt,
          id: call._id,
          notes: text,
        });
      }
    },
    acceptCall(call) {
      var g = this;
      g.call = call;
      g.audio?.pause();
      const peer = new Peer();
      peer.on("call", (call) => {
        console.log(2, call);
        navigator.getUserMedia =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia;
        // Get access to microphone
        navigator.getUserMedia(
          // Only request audio
          { video: false, audio: true },

          // Success callback
          function success(localAudioStream) {
            g.mediaStream = localAudioStream;
            localAudioStream = g.mediaStream;
            // Do something with audio stream
            call.answer(localAudioStream);
            call.on("stream", (remoteStream) => {
              console.log(remoteStream);
              var audio = $("<video autoplay />").appendTo("body");
              audio[0].srcObject = remoteStream;
            });
          },
          // Failure callback
          function error(err) {
            console.log(err);
            // handle error
          }
        );
      });
      peer.on("open", function (id) {
        console.log("My peer ID is: " + id);
        $.post(api + "/console/calls/accept", {
          jwt: g.user.jwt,
          id: call._id,
          peer_id: id,
        }).then(function (r) {
          r = JSON.parse(r);
          g.call.status = "1";
          g.call.stats = r.response;
          g.sockets.subscribe("call-ended", function (data) {
            location.reload();
          });
          peer.on("connection", function (conn) {
            console.log(1, conn);
          });
        });
        //

        //
      });
    },
    rejectCall(call) {
      var g = this;
      g.audio?.pause();
      g.call = call;
      $.post(api + "/console/calls/reject", {
        jwt: this.user.jwt,
        id: call._id,
      }).then(function () {
        g.call = {};
        g.audio.pause();
        //
      });
    },
    getCalls() {
      var g = this;
      $.post(api + "/console/calls/index", {
        jwt: this.user.jwt,
      }).then(function (r) {
        g.calls = JSON.parse(r).response;
        //
      });
    },
    seenMissed(id) {
      $.post(api + "/console/calls/missed-seen", {
        jwt: this.user.jwt,
        id: id,
      });
    },
    callTime(start, end) {
      if (end) {
        var seconds = (
          (new Date(end).getTime() - new Date(start).getTime()) /
          1000
        ).toFixed(0);
        if (seconds > 60) {
          return (seconds / 60).toFixed(0) + " دقيقة";
        } else {
          return seconds + " ثانية";
        }
      } else {
        var seconds = (
          (new Date().getTime() - new Date(start).getTime()) /
          1000
        ).toFixed(0);
        if (seconds > 60) {
          return (seconds / 60).toFixed(0) + " دقيقة";
        } else {
          return seconds + " ثانية";
        }
      }
    },
  },
  async created() {
    var g = this;
    $.post(api + "/console/general/admins-names", {
      jwt: this.user.jwt,
    }).then(function (r) {
      g.admins = {};
      JSON.parse(r).response.forEach((a) => {
        g.admins[a._id] = a;
      });
    });
    $.post(api + "/console/general/services", {
      jwt: this.user.jwt,
    }).then(function (r) {
      g.services = {};
      JSON.parse(r).response.forEach((a) => {
        g.services[a.code] = a;
      });
    });
    g.sockets.subscribe("notification", (d) => {
      var audio = new Audio(
        "https://cdn-static.brmja.com/storage/uploads/files/620511d8d5536534353593.mp3"
      );
      audio.play();
      g.$toast({
        component: ToastificationContent,
        props: {
          title: d,
          icon: "BellIcon",
          variant: "success",
        },
      });
    });
    setInterval(() => {
      g.calls = JSON.parse(JSON.stringify(g.calls));
    }, 1000);
    g.sockets.subscribe("income-call", (d) => {
      g.audio = new Audio(require("@/assets/income-call.mp3"));
      g.audio.play();
    });
    g.sockets.subscribe("refresh-calls", (d) => {
      g.getCalls();
    });
    g.getCalls();
  },
};
</script>
<style>
.income-call {
  position: fixed !important;
  top: 105px;
  right: 15px;
  z-index: 9999;
  width: 300px;
}
.income-call .card {
  border: 2px solid rgb(165, 42, 165);
  box-shadow: -1px 0px 12px -3px rgba(0, 0, 0, 0.75);
}
.current-call {
  position: fixed !important;
  bottom: -24px !important;
  left: 0px;
  z-index: 9999;
  width: 100%;
}
.current-call .card {
  border: 2px solid lightseagreen;
}
.border-left-success {
  border-left: solid 2px purple;
}
</style>